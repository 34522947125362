export const magasinIdCurrent = 'LKIN';
export const appCurrency = { name: 'EUR', symbol: 'MAD (TTC)' };
export const productsPerPage = 16;
export const productsPerSearch = 8;
export const priceSliderDefaultMax = 9999;
export const priceSliderDefaultMin = 0;
export const passwordMandatoryLength = 6;
export const notificationDuration = 3000;

// special categories
export const OTHER_CATEGORY_NUMBER = 1;
export const RECOMMENDED_SUBCATEGORY_NUMBER = 10;
export const HEART_SUBCATEGORY_NUMBER = 11;
export const BEST_SELLERS_SUBCATEGORY_NUMBER = 12;
export const PROMO_SUBCATEGORY_NUMBER = 13;
export const COIN_SUBCATEGORY_NUMBER = 14;
//-----
export const ACCESS_TOKEN_LIFE_SPAN = 1800000;
export const RENEW_TOKEN_LIFE_SPAN = 86400000;
export const DAY_IN_MILISECONDS = 86400000;
export const STANDARD_DELIVERY_ESTIMATED_DELAY = 2;
export const DELAYED_DELIVERY_ESTIMATED_DELAY = 7;

// delivery
export const DELIVERY_PRODUCTS_ID_FREE = 0;
export const DELIVERY_PRODUCTS_ID_STANDARD = 1;
export const FREE_DELIVERY_TEXT = 'Livraison gratuite';
export const TAKEOUT_DELIVERY_TEXT = 'A récupérer au magasin';

// COOKIES TEXT
export const COOKIES_CONSENT =
  "Nous utilisons des cookies pour améliorer votre expérience de navigation. En continuant, vous consentez à notre utilisation de cookies. Ces cookies collectent des informations pour améliorer et personnaliser votre expérience, fournissant des analyses sur nos visiteurs sur ce site et d'autres plateformes.";
// ERROR TEXT
export const ERROR_GENERAL = 'Une erreur est survenue, veuillez réessayer plus tard';
export const ERROR_PAYMENT = 'Paiement échoué. Veuillez réessayer ou contacter le support pour assistance.';
export const ERROR_GETTING_DATA =
  "Une erreur s'est produite lors de la tentative de récupération des données, veuillez actualiser la page";
export const ERROR_LOGIN = "Une erreur s'est produite lors de la tentative de connexion";
export const ERROR_SEARCH = "Une erreur s'est produite lors de la recherche de votre requête";
export const ERROR_SEND_FORM = "une erreur s'est produite lors de la tentative d'envoi du formulaire";
export const ERROR_ENTER_VALID_EMAIL = 'Veuillez entrer un email valide';
export const ERROR_WRONG_EMAIL_OR_PASSWORD = 'Email ou mot de passe erroné';
export const ERROR_EMPTY_FIELDS = 'Veuillez remplir tous les champs nécessaires.';
export const ERROR_PASS_LENGTH = `Le mot de passe doit contenir au moins ${passwordMandatoryLength} caractères`;
export const ERROR_PASSWORDS_NOT_MATCH = 'Les mots de passe que vous avez fournis ne correspondent pas';
export const ERROR_RESETING_PASS = `Une erreur s'est produite lors de la tentative de réinitialisation de votre mot de passe`;
export const ERROR_RECLICK_EMAIL_LINK = `Essayez de fermer cette fenêtre et de cliquer à nouveau sur le lien contenu dans l'e-mail`;
export const ERROR_NEWSLETTERS_SUB = `L'abonnement a échoué.`;

// SUCCESS TEXT
export const SUCCESS_RESET_PASS = `Le mot de passe a été réinitialisé avec succès`;
export const SUCCESS_MESSAGE_SEND = `Votre message a été envoyé avec succès.`;
export const SUCCESS_NEWSLETTERS_SUB = `Abonnement réussi.`;

// EMAILS
export const EMAIL_TEXT_CONTACT_FORM = `Soumission du formulaire de contact`;

// DELIVERY TEXT
export const OUT_OF_STOCK_TEXT = `Un ou plusieurs produits sont en rupture de stock (délai 1 semaine).`;
export const GROUP_DELIVERY_TEXT = `Souhaitez-vous une livraison groupée (moins les frais de livraison)`;
export const GROUP_DELIVERY_QUESTION = `Livraison groupée`;
export const TAKE_OUT_DELIVERY_QUESTION = `A récupérer au magasin`;
export const TAKE_OUT_ADDRESS_QUESTION = `Veuillez choisir le magasin:`;
